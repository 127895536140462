import type { FormPostSubmitFields } from "../../translation-keys/post-submit-text-keys.js";

const BlindHeading ="Welcome Back!";
const BlindSubmitBody ="Click the button below to download this asset.";
const Heading ="Thank you for your interest!";
const Body ="We have sent an email to the provided address with a download link to the requested asset. You may also click the button below to start your download.";
const Download = "Download Now";
const PostFormFieldMap: FormPostSubmitFields = {
  "document-BlindSubmitHeading": BlindHeading,
  "document-BlindSubmitBody": BlindSubmitBody,
  "document-Heading": Heading,
  "document-Body": Body,
  "document-Download": Download,
  "document-landing-page-BlindSubmitHeading": BlindHeading,
  "document-landing-page-BlindSubmitBody": BlindSubmitBody,
  "document-landing-page-Heading": Heading,
  "document-landing-page-Body": Body,
  "document-landing-page-Download": Download,
  "post-event-assets-BlindSubmitHeading": BlindHeading,
  "post-event-assets-BlindSubmitBody": BlindSubmitBody,
  "post-event-assets-Heading": Heading,
  "post-event-assets-Body": "Click the button below to watch the video.",
  "post-event-assets-Download": "Play Now",
  "ppc-BlindSubmitHeading": BlindHeading,
  "ppc-BlindSubmitBody": BlindSubmitBody,
  "ppc-Heading": "Thank you for filling out our form.",
  "ppc-Body": Body,
  "ppc-Download": Download,
  "case-study-BlindSubmitHeading": BlindHeading,
  "case-study-BlindSubmitBody": BlindSubmitBody,
  "case-study-Heading": "Thank you for filling out the form. Please click the download button below to view your requested resource.",
  "case-study-Body": "",
  "case-study-Download": "Download Your Request",
  "video-BlindSubmitHeading": "Thank you for your interest in our content.",
  "video-BlindSubmitBody": "",
  "video-Heading": "Your files are on their way.",
  "video-Body": "The resources are on their way to your email but you can also download them right now. Feel free to continue exploring and learning more about MasterControl and our solutions.",
  "video-Download": Download,
  "video-landing-page-BlindSubmitHeading": BlindHeading,
  "video-landing-page-BlindSubmitBody": BlindSubmitBody,
  "video-landing-page-Heading": "Thank you for your interest in our content. Click the button below to watch the video.",
  "video-landing-page-Body": "You have unlocked access to all of MasterControl's exclusive documents and videos. Visit the Resource Center",
  "video-landing-page-Download": Download,
  "video-gated-BlindSubmitHeading": BlindHeading,
  "video-gated-BlindSubmitBody": BlindSubmitBody,
  "video-gated-Heading": "Thank you for your interest in our content. Click the button below to watch the video.",
  "video-gated-Body": "You have unlocked access to all of MasterControl's exclusive documents and videos. Visit the Resource Center",
  "video-gated-Download": Download,
  "recommended-resources-BlindSubmitHeading": "",
  "recommended-resources-BlindSubmitBody": "",
  "recommended-resources-Heading": Heading,
  "recommended-resources-Body": " We have sent an email to the provided address with a download link to the requested asset. You may also click the button to start your download.",
  "recommended-resources-Download": "resource center",
  "general-modal-BlindSubmitHeading": "",
  "general-modal-BlindSubmitBody": "",
  "general-modal-Heading": "Your Requested Resource",
  "general-modal-Body": "Thank you! You will receive an email with your document link or you can download it now.",
  "general-modal-Download": "",
};
export default PostFormFieldMap;
